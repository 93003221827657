import React, { ComponentPropsWithRef, FC } from 'react';
import LazyLoad from 'react-lazyload';
import { useSelector } from 'react-redux';
import { RootState } from '@store/store';
import classnames from 'classnames';
import dynamic from 'next/dynamic';

import './CustomJournal.scss';

const CustomJournalConnected = dynamic<ComponentPropsWithRef<'div'>>(
  () => import('./CustomJournalConnected').then(mod => mod.CustomJournalConnected),
  { ssr: false }
);
const CustomJournalForm = dynamic<ComponentPropsWithRef<'div'>>(
  () => import('./CustomJournalForm').then(mod => mod.CustomJournalForm),
  { ssr: false }
);

type Props = ComponentPropsWithRef<'section'>;

const CustomJournal: FC<Props> = ({ className, ...rest }) => {
  const rootClass = classnames('journal-custom', className);
  const {
    settings: {
      preferences: { isPrefsSet },
    },
    auth: { isAuthenticated },
  } = useSelector((state: RootState) => state);

  return (
    <section className={rootClass} id="plc-journal-section" {...rest}>
      <LazyLoad offset={200} resize={true}>
        {isPrefsSet && isAuthenticated ? <CustomJournalConnected /> : <CustomJournalForm />}
      </LazyLoad>
    </section>
  );
};

export { CustomJournal };
