import { ContentType, ForecastParamCode, Geopoint, SearchType, TimeStepCode } from 'pleinchamp-api-client';
import { MeteoApi } from '@api/business/api.utils';
import { computeMeteoInfoFromDateAndPostion } from '@meteo/business/meteo.utils';
import { searchContent } from '@search/business/search.utils';
import { Constants } from '@utils/Constants';
import { plcDayjs } from '@utils/date';

export function callForecasts(...params: Parameters<typeof MeteoApi.getForecasts>) {
  return MeteoApi.getForecasts(...params).then(response => response.data);
}

export function fetchMeteoNews() {
  return searchContent({
    contentTypes: [ContentType.Article],
    limit: Constants.FEATURED_NEWS_LIMIT,
    searchType: SearchType.Prioritize,
  }).then(data => data.contents);
}

function getFetchForecastsParameters(widget: '36' | '5' | '15' | 'summary') {
  const from = plcDayjs().startOf('hour');

  switch (widget) {
    case '36': {
      // On retire 2 heures au from pour se positionner sur la tranche précédent l'heure actuelle car il y a un pas de 3 à chaque fois.
      return { from: from.subtract(2, 'hour'), timeSteps: [TimeStepCode.TimeStep3], until: from.add(36, 'hour') };
    }
    case '5': {
      return { from, timeSteps: [TimeStepCode.TimeStep24], until: from.add(4, 'day') };
    }
    case '15': {
      return { from, timeSteps: [TimeStepCode.TimeStep24], until: from.add(14, 'day') };
    }
    case 'summary': {
      const dateForSummary = from.add(1, 'hour');

      return {
        from: dateForSummary,
        timeSteps: [TimeStepCode.TimeStep1, TimeStepCode.TimeStep24],
        until: dateForSummary,
      };
    }
    default: {
      const switchTest: never = widget;
      return switchTest;
    }
  }
}

export function fetchForecasts(coord: Geopoint | undefined, forecastsCodes: ForecastParamCode[]) {
  const { latitude, longitude } = coord || {};
  if (!latitude || !longitude) {
    return Promise.resolve(undefined);
  }
  const { from, timeSteps, until } = getFetchForecastsParameters('summary');
  return callForecasts(latitude, longitude, timeSteps, from.toDate(), until.toDate(), forecastsCodes);
}

export async function fetch36HoursForecasts(coord: Geopoint | undefined, forecastsCodes: ForecastParamCode[]) {
  if (!coord) {
    return Promise.resolve([]);
  }

  const { latitude, longitude } = coord;
  const { from, timeSteps, until } = getFetchForecastsParameters('36');
  const forecasts = await callForecasts(latitude, longitude, timeSteps, from.toDate(), until.toDate(), forecastsCodes);

  return forecasts?.timeStep3 || [];
}

export async function fetch15DaysForecasts(
  coord: Geopoint | undefined,
  forecastsCodes: ForecastParamCode[],
  isPro?: boolean
) {
  if (!coord) {
    return Promise.resolve([]);
  }

  const { latitude, longitude } = coord;
  const { from, timeSteps, until } = getFetchForecastsParameters(isPro ? '15' : '5');
  const forecasts = await callForecasts(latitude, longitude, timeSteps, from.toDate(), until.toDate(), forecastsCodes);

  return (forecasts?.timeStep24 || []).map(forecast => {
    const { sunset, sunrise, moonPhase } = computeMeteoInfoFromDateAndPostion(
      new Date(forecast.date),
      latitude,
      longitude
    );
    return {
      ...forecast,
      moonPhase,
      sunrise: plcDayjs(sunrise).format('HH:mm'),
      sunset: plcDayjs(sunset).format('HH:mm'),
    };
  });
}
