/* eslint-disable no-nested-ternary */
import React, { ComponentProps } from 'react';
import { Text } from '@components/Text/Text.component';
import { TextProps } from '@components/Text/Text.types';
import { ApiStatusCode } from '@utils/api.utils';
import classnames from 'classnames';

type Props = Omit<ComponentProps<typeof Text>, 'variant'> & {
  variant?: TextProps['variant'];
  errorCode?: number;
};

const managedErrorCodes: Props['errorCode'][] = [ApiStatusCode.notFound, ApiStatusCode.unauthorized];

function PlcMessage({
  className,
  tag = 'p',
  variant = 'small',
  flavour = 'red',
  i18nKey,
  errorCode,
  children,
  ...rest
}: Props) {
  const rootClassNames = classnames('plc-msg', className);
  return (
    <Text
      className={rootClassNames}
      flavour={flavour}
      i18nKey={
        i18nKey ||
        (children
          ? undefined
          : managedErrorCodes.includes(errorCode)
          ? `common.error.${errorCode}`
          : 'common.error.generic')
      }
      tag={tag}
      variant={variant}
      {...rest}
    >
      {children}
    </Text>
  );
}

export { PlcMessage };
