import React, { ComponentPropsWithRef, FC, ReactNode, useRef, useState } from 'react';
import SlickSlider from 'react-slick';
import { NextArrow } from '@components/Arrows/NextArrow.component';
import { PreviousArrow } from '@components/Arrows/PreviousArrow.component';
import { ClippedImage } from '@components/ClippedImage/ClippedImage.component';
import { Breakpoint, useIsBreakpointUp } from '@styles/breakpoints';
import { ClippedImageStyle } from '@utils/images';
import classnames from 'classnames';
import dynamic from 'next/dynamic';

import './JournalSlides.scss';

const JournalStocks = dynamic<ComponentPropsWithRef<'div'>>(
  () => import('./JournalStocks.component').then(module => module.JournalStocks),
  {
    ssr: false,
  }
);

const JournalWeather = dynamic<ComponentPropsWithRef<'div'>>(() =>
  import('./JournalWeather.component').then(module => module.JournalWeather)
);

const DotNavigator: FC<{ dots: ReactNode; index: number }> = ({ dots, index }) => {
  const x = index * 60;
  const transform = `translateX(${x}px)`;

  return (
    <div className="dot-navigator">
      <ul>
        <div className="dot-indicator" style={{ transform }} />
        {dots}
      </ul>
    </div>
  );
};

const JournalSlides: FC<ComponentPropsWithRef<'div'>> = ({ className, ...rest }) => {
  const rootClass = classnames('journal-main-slider', 'flex-up-m', className);
  const sliderRef = useRef<SlickSlider>(null);
  const sliderImgRef = useRef<SlickSlider>(null);
  const isDesktop = useIsBreakpointUp(Breakpoint.m);
  const initialSlide = 0;
  const [index, setIndex] = useState(initialSlide);

  function handleChangeSlide(_: number, newIndex: number) {
    setIndex(newIndex);
    if (!sliderImgRef || !sliderImgRef.current) {
      return;
    }
    sliderImgRef.current.slickGoTo(newIndex);
  }

  return (
    <div className={rootClass} {...rest}>
      {isDesktop && (
        <SlickSlider
          ref={sliderImgRef}
          arrows={false}
          autoplay={false}
          className="journal-main-slider-images"
          dots={false}
          fade
          infinite={false}
          lazyLoad="ondemand"
          swipe={false}
        >
          <div className="img-slide">
            <ClippedImage
              alt=""
              clipStyle={ClippedImageStyle.journalSlide}
              plcSrc={{ src: 'journal-meteo', type: 'local' }}
            />
          </div>
          <div className="img-slide">
            <ClippedImage
              alt=""
              clipStyle={ClippedImageStyle.journalSlide}
              plcSrc={{ src: 'journal-stocks', type: 'local' }}
            />
          </div>
        </SlickSlider>
      )}
      <SlickSlider
        ref={sliderRef}
        adaptiveHeight
        appendDots={dots => <DotNavigator dots={dots} index={index} />}
        asNavFor={sliderImgRef?.current || undefined}
        autoplay
        autoplaySpeed={20000}
        beforeChange={handleChangeSlide}
        className="journal-main-slider-content plc-mb-l plc-mb-up-m-xl"
        cssEase="ease-out"
        dots
        initialSlide={initialSlide}
        nextArrow={<NextArrow />}
        pauseOnDotsHover
        pauseOnHover
        prevArrow={<PreviousArrow />}
        swipe={!isDesktop}
      >
        <JournalWeather />
        <JournalStocks />
      </SlickSlider>
    </div>
  );
};

export { JournalSlides };
