import React, { ComponentPropsWithRef, FC } from 'react';
import { PlcImgLoader } from '@components/Loaders/PlcImgLoader.component';
import { PlcTextListLoader } from '@components/Loaders/PlcTextLoader.component';
import { Spacing } from '@styles/Spacing.style';
import classnames from 'classnames';

import './ContentCard.scss';

type Props = ComponentPropsWithRef<'article'> & { uniqueKey?: string };

const ContentCardLoader: FC<Props> = ({ className, uniqueKey = 'my-random-value', ...rest }) => {
  const rootClass = classnames('content-card', className);
  const width = 325;
  return (
    <article className={rootClass} {...rest}>
      <header>
        <div className="card-image">
          <PlcImgLoader height={272} uniqueKey={`${uniqueKey}-img`} width={width} />
        </div>
      </header>
      <div className="card-body">
        <PlcTextListLoader
          textProps={[
            { bottomSpacing: Spacing.xxs, variant: 'h6', width: 75 },
            { bottomSpacing: Spacing.m, numberOfLine: 3, variant: 'h4', width: 0.7 * width },
            { variant: 'p', width: 140 },
          ]}
          uniqueKey={`${uniqueKey}-txt`}
          width={width}
        />
      </div>
    </article>
  );
};

export { ContentCardLoader };
