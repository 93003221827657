import { Content } from 'pleinchamp-api-client';
import React, { ComponentProps, FC } from 'react';
import { Text } from '@components/Text/Text.component';
import { HighlightedContent } from '@content/HighlightedContent/HighlightedContent.component';
import { formatDateCompact, formatDateToIso } from '@utils/date';
import { getSlugLinkProps, urlEnum } from '@utils/url';

type Props = ComponentProps<'article'> & {
  article: Content;
  isMobile: boolean;
};

export const HighlightedArticle: FC<Props> = ({ article, isMobile, ...rest }) => {
  const { slug, articleDetail, publishDate } = article;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { thumbnailMedia, thumbnailMediaMobile, subtitle } = articleDetail!;
  const linkProps = getSlugLinkProps(urlEnum.actu, slug);

  return (
    <HighlightedContent
      content={article}
      description={subtitle}
      header={
        <Text className="block plc-mb-s" dateTime={formatDateToIso(publishDate)} tag="time" variant="h6">
          {formatDateCompact(publishDate)}
        </Text>
      }
      linkI18nKey="actu.card-action"
      linkProps={linkProps}
      media={isMobile && thumbnailMediaMobile ? thumbnailMediaMobile : thumbnailMedia}
      {...rest}
    />
  );
};
