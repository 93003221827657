import { PlcAnchor } from '../PlcAnchor/PlcAnchor.component';
import { LinkProps } from 'next/link';
import React, { ComponentPropsWithRef, FC } from 'react';
import { FlatButtonProps, FlatButtonVariant } from '@components/buttons/Buttons.type';
import { FlatButton } from '@components/buttons/FlatButton/FlatButton.component';
import { Breakpoint, useIsBreakpointDown } from '@styles/breakpoints';
import classnames from 'classnames';

import './PlcButtonGroup.scss';

type ExtendedFlatButtonProps = FlatButtonProps & {
  linkProps?: LinkProps;
  variant: FlatButtonVariant;
  textI18nKey?: string;
};

type Props = ComponentPropsWithRef<'footer'> & {
  mobileReverse?: boolean;
  desktopFullWidth?: boolean;
  buttons: (ExtendedFlatButtonProps | undefined)[];
};

const buildButtonComponent = (isMobile: boolean) => (button: ExtendedFlatButtonProps | undefined, index: number) => {
  if (!button) {
    return <div key={`div-${index}`} />;
  }

  const { linkProps, isSmall = !isMobile, textProps = {}, textI18nKey, type, ...rest } = button;
  const { i18nKey = textI18nKey, variant = isMobile ? 'small' : undefined, ...restTextProps } = textProps;
  const flatButtonComponent = (
    <FlatButton
      key={`comp-${index}`}
      className="flex-1"
      isSmall={isSmall}
      textProps={{ i18nKey, variant, ...restTextProps }}
      type={type}
      wrapperClassName="flex-1 block-up-m flex-0-up-s"
      {...rest}
    />
  );

  if (!linkProps) {
    return flatButtonComponent;
  }

  return (
    <PlcAnchor key={`link-${index}`} className={`flex ${isMobile && 'plc-mb-m'} block-up-m`} linkProps={linkProps}>
      {flatButtonComponent}
    </PlcAnchor>
  );
};

const PlcButtonGroup: FC<Props> = ({ className, mobileReverse, buttons, desktopFullWidth = true, ...rest }) => {
  const isMobile = useIsBreakpointDown(Breakpoint.m);
  const rootClass = classnames(
    'button-group',
    isMobile || desktopFullWidth ? 'flex' : null,
    { mobileReverse },
    className
  );
  return (
    <footer className={rootClass} role="group" {...rest}>
      {buttons.map(buildButtonComponent(isMobile))}
    </footer>
  );
};

export { PlcButtonGroup };
