import { CustomJournal } from '../CustomJournal/CustomJournal';
import { JournalAgenda } from '../JournalAgenda/JournalAgenda.component';
import { JournalFreeField } from '../JournalFreeField/JournalFreeField.component';
import { JournalNewsHeadlines } from '../JournalNewsHeadlines/JournalNewsHeadlines.component';
import { JournalSlides } from '../JournalSlides/JournalSlides.component';
import { JournalContext } from 'pages/index';
import React, { ComponentPropsWithRef, useContext } from 'react';
import { useSelector } from 'react-redux';
import { ADVERT_FORMAT_ID, ADVERT_PAGE_ID } from '@adverts/business/Adverts.contants';
import { useAdvert } from '@adverts/business/useAdvert';
import { AdProps } from '@adverts/components/GenericAd.component';
import { usePlcApiCall } from '@api/business/api.hook';
import { fetchMostViewedNews } from '@api/business/api.utils';
import { PrimaryButton } from '@components/buttons/FlatButton/FlatButton.component';
import { MostViewedContent } from '@components/content/MostViewedContent/MostViewedContent.component';
import { Text } from '@components/Text/Text.component';
import { PlcAnchor } from '@layout/components/PlcAnchor/PlcAnchor.component';
import { PlcPageLayout } from '@layout/components/PlcPageLayout/PlcPageLayout.component';
import LastAnalysis from '@stocks/components/LastAnalysis/LastAnalysis.component';
import { Breakpoint, useIsBreakpointDown } from '@styles/breakpoints';
import { userSelectors } from '@user/business/User.store';
import { NB_JOURNAL_MOST_VIEWED_NEWS } from '@utils/api.utils';
import { urlEnum } from '@utils/url';
import { ATLevel2 } from 'features/tagPlan/business/TagPlan.types';
import dynamic from 'next/dynamic';

const TAG_PROPS = {
  level2: ATLevel2.Journal,
  tagPageNameI18nArray: ['journal.tag.title'],
};

const HomeBannerComponent = dynamic<ComponentPropsWithRef<'div'>>(() =>
  import('@components/HomeBanner/HomeBanner.component').then(module => module.HomeBannerComponent)
);

const GenericAd = dynamic<AdProps>(
  () => import('@adverts/components/GenericAd.component').then(module => module.GenericAd),
  {
    ssr: false,
  }
);

const JournalPro = dynamic<ComponentPropsWithRef<'div'>>(
  () => import('../JournalPro/JournalPro.component').then(module => module.JournalPro),
  {
    ssr: false,
  }
);

function JournalLayout() {
  const {
    mostViewedNews: { initialData },
  } = useContext(JournalContext);
  const mostViewedNews = usePlcApiCall(fetchMostViewedNews, [NB_JOURNAL_MOST_VIEWED_NEWS], {
    initialData,
  });
  const isPro = useSelector(userSelectors.isPro);

  const isSmartphone = useIsBreakpointDown(Breakpoint.s);

  useAdvert({
    pageId: ADVERT_PAGE_ID.Journal,
  });

  return (
    <PlcPageLayout
      appNameAsPrefix
      className="journal plc-mb-0"
      metaDescI18n="journal.meta-desc"
      metaTitleI18n="journal.meta-title"
      tagProps={TAG_PROPS}
    >
      <GenericAd formatId={isSmartphone ? ADVERT_FORMAT_ID.MegaBanner : ADVERT_FORMAT_ID.Arch} />
      {/* For SEO purpose, have a hidden h1 title (PLC-2455) */}
      <Text i18nKey="common.app-name" style={{ position: 'absolute', top: -1000 }} variant="h1" />
      <JournalSlides />
      <JournalNewsHeadlines className="wrapper-s plc-mb-l plc-mb-up-m-xl" />
      <LastAnalysis parentDivClass="wrapper-s last-analyse-flex-container plc-mb-basis" />

      <PlcAnchor
        className="wrapper-s plc-mb-xl flex block-up-s"
        linkProps={{ as: urlEnum.stocks, href: urlEnum.stocks }}
      >
        <PrimaryButton
          className="flex flex-1 block-up-s"
          textProps={{ i18nKey: 'journal.stocks.button.all' }}
          wrapperClassName="flex-1"
        />
      </PlcAnchor>
      <HomeBannerComponent />

      <CustomJournal className="background-ultralight plc-py-l plc-py-up-m-xl plc-mb-l plc-mb-up-m-xl" />
      <GenericAd className="wrapper-s plc-mb-basis InlineVideoIndex" formatId={ADVERT_FORMAT_ID.InlineVideo} />
      <JournalAgenda className="wrapper-s" />
      <JournalFreeField />
      <MostViewedContent className="wrapper-s plc-mb-l" {...mostViewedNews} />
      {!isPro && <JournalPro />}
    </PlcPageLayout>
  );
}

export { JournalLayout };
