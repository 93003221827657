import kebabCase from 'lodash/kebabCase';
import {
  Analysis,
  MarketForLastAnalysis,
  ProductCategoryCode,
  ProductTypeForLastAnalysis,
} from 'pleinchamp-api-client';
import React, { ComponentProps, FC } from 'react';
import { PlcApiResponseInterface } from '@api/business/api.hook';
import { Text } from '@components/Text/Text.component';
import { TextWithArrow } from '@components/Text/TextWithArrow.component';
import { PlcAnchor } from '@layout/components/PlcAnchor/PlcAnchor.component';
import { getUrlStockTypeString, transformAnalyseTabToUrl } from '@stocks/business/Stocks.utils';
import { StocksAnalysisTab } from '@stocks/business/StocksAnalysisTab.types';
import { formatDateDayAndMonth } from '@utils/date';
import { urlEnum } from '@utils/url';

import './LastAnalysisComponent.scss';

type Props = ComponentProps<'div'> & {
  variant?: 'normal' | 'compact';
  i18nTitle?: string;
  i18nTitleSuite?: string;
  isMobile?: boolean;
} & PlcApiResponseInterface<Analysis[]>;
function truncate(str: string, n: number) {
  return str.length > n ? `${str.substr(0, n - 1)}...` : str;
}
function getLinkForLastAnalysis(
  productType: ProductTypeForLastAnalysis,
  analyseTab: StocksAnalysisTab,
  market?: MarketForLastAnalysis
) {
  const tabUrl = transformAnalyseTabToUrl(analyseTab);

  const productParams = [[productType.id.toString(), kebabCase(productType.name)].join('-'), tabUrl];

  const href = `${urlEnum.stocks}/[stockType]/[...productParams]`;
  if (market && productType.productCategoryCode === ProductCategoryCode.Cattle) {
    productParams.push([market.id.toString(), kebabCase(market.name)].join('-'));
  }
  const as = `${urlEnum.stocks}/${getUrlStockTypeString(productType.productCategoryCode)}/${productParams.join('/')}`;
  // /#tab-analysis`;

  return {
    as,
    href,
  };
}
function titleLastAnalysis(r: Analysis, isMobile: boolean) {
  const titleFinal = `${formatDateDayAndMonth(r.date)} ${r.productType?.name} : ${r.title ? r.title : ''}`;
  const valueTruncate = isMobile ? 28 : 100;

  return truncate(titleFinal, valueTruncate);
}
function classTitleLastAnalysis(titleAnalyseSuite: string, isMobile: boolean) {
  if (!titleAnalyseSuite && !isMobile) {
    return 'plc-mb-basis';
  }
  return '';
}
export const LastAnalysisComponent: FC<Props> = ({
  i18nTitle = 'common.last-analysis',
  i18nTitleSuite = '',
  isMobile = false,
  className,
  data,
  isValidating,
  error,
  retryCallback,
  ...rest
}) => {
  if (error && !isValidating) {
    return null;
  }

  return (
    <div className={className} {...rest}>
      <Text
        className={classTitleLastAnalysis(i18nTitleSuite, isMobile)}
        i18nKey={i18nTitle}
        tag="p"
        variant="p-style-h3"
      />
      <Text className={isMobile ? '' : 'plc-mb-basis'} i18nKey={i18nTitleSuite} tag="p" variant="p-style-h3" />
      <ol className="ol-analyse">
        {data &&
          data.map(r => (
            <li key={r.id}>
              <PlcAnchor
                linkProps={getLinkForLastAnalysis(
                  r.productType as ProductTypeForLastAnalysis,
                  StocksAnalysisTab.Analyse,
                  r.productType?.marketReference
                )}
              >
                <TextWithArrow tag="div" variant="p">
                  {titleLastAnalysis(r, isMobile)}
                </TextWithArrow>
              </PlcAnchor>
            </li>
          ))}
      </ol>
    </div>
  );
};
