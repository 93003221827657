/* eslint-disable react/destructuring-assignment */
import { GetStaticProps } from 'next';
import Head from 'next/head';
import { FreeField } from 'pleinchamp-api-client/dist/src/content/content';
import { Content } from 'pleinchamp-api-client/dist/src/content/search';
import { Analysis } from 'pleinchamp-api-client/dist/src/market/market';
import React, { createContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchLastAnalysis, fetchMostViewedNews } from '@api/business/api.utils';
import { MostViewedContent } from '@components/content/MostViewedContent/MostViewedContent.component';
import { JournalLayout } from '@journal/components/JournalLayout/JournalLayout.component';
import { PlcDispatch } from '@store/store';
import { formatPropsForStatic, NB_JOURNAL_MOST_VIEWED_NEWS } from '@utils/api.utils';
import { fetchEvents, fetchFreeField, fetchNewHeadlines } from '@utils/fetch';
import { defaultNamespaces } from '@utils/strings';
import { PlcNextPage, PlcPageContextProps } from 'features/business/app.types';

interface Props {
  events: Content[];
  newsHeadlines: Content[];
  customNews: Content[];
  freeFieldContent: FreeField | undefined;
  mostViewedNews: MostViewedContent[];
  analyse: Analysis[];
}

export const JournalContext = createContext<PlcPageContextProps<Props>>({
  analyse: { isValidating: false },
  customNews: { isValidating: false },
  events: { isValidating: false },
  freeFieldContent: { isValidating: false },
  mostViewedNews: { isValidating: false },
  newsHeadlines: { isValidating: false },
});

const IndexPage: PlcNextPage<Partial<Props>> = props => {
  const {
    settings: { fetchUserProductTypes },
  } = useDispatch<PlcDispatch>();

  useEffect(() => {
    fetchUserProductTypes();
  }, []);

  return (
    <JournalContext.Provider
      value={{
        analyse: { initialData: props.analyse, isValidating: false },
        customNews: { initialData: props.customNews, isValidating: false },
        events: { initialData: props.events, isValidating: false },
        freeFieldContent: { initialData: props.freeFieldContent, isValidating: false },
        mostViewedNews: { initialData: props.mostViewedNews, isValidating: false },
        newsHeadlines: { initialData: props.newsHeadlines, isValidating: false },
      }}
    >
      <Head>
        <meta content="7200" httpEquiv="refresh" />
      </Head>
      <JournalLayout />
    </JournalContext.Provider>
  );
};

export const getStaticProps: GetStaticProps = async () => {
  const pageDefaultState = {
    namespacesRequired: [...defaultNamespaces, 'journal'],
  };

  const props = await Promise.all([
    fetchEvents(),
    fetchNewHeadlines(),
    fetchFreeField(),
    fetchMostViewedNews(NB_JOURNAL_MOST_VIEWED_NEWS),
    fetchLastAnalysis(),
  ])
    .then(([events, newsHeadlines, freeFieldContent, mostViewedNews, analyse]) => ({
      ...pageDefaultState,
      analyse,
      events,
      freeFieldContent,
      mostViewedNews,
      newsHeadlines,
    }))
    .catch(err => {
      console.error('Error occured on /index.tsx', err);
      return pageDefaultState;
    });

  return formatPropsForStatic(props);
};

export default IndexPage;
