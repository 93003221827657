import React from 'react';
import { ChevronRightIcon } from '@components/icons/ChevronRight.icon';
import classnames from 'classnames';

import './Arrows.scss';

const NextArrow = ({ className, style, onClick }: any) => (
  <button className={classnames('slick-arrow', 'slick-next', className)} onClick={onClick} style={style} type="button">
    <ChevronRightIcon height={15} width={10} />
  </button>
);

export { NextArrow };
