import React from 'react';
import { ChevronLeftIcon } from '@components/icons/ChevronLeft.icon';
import classnames from 'classnames';

import './Arrows.scss';

const PreviousArrow = ({ className, style, onClick }: any) => (
  <button
    className={classnames('slick-arrow', 'slick-previous', className)}
    onClick={onClick}
    style={style}
    type="button"
  >
    <ChevronLeftIcon height={15} width={10} />
  </button>
);

export { PreviousArrow };
