import { AnimatedButton } from '../AnimatedButton/AnimatedButton.component';
import { FlatButtonProps, FlatButtonVariant } from '../Buttons.type';
import React, { FC, forwardRef } from 'react';
import { TextPropsOptionalVariant } from '@components/Text/Text.types';
import { TextWithArrow } from '@components/Text/TextWithArrow.component';
import classnames from 'classnames';

import './FlatButton.scss';

function buildTextFlavour(flavour: TextPropsOptionalVariant['flavour'], variant: FlatButtonVariant, isPro: boolean) {
  if (!flavour) {
    return variant === FlatButtonVariant.primary && !isPro ? 'light' : 'dark';
  }
  return flavour;
}

function buildTextVariant(v: TextPropsOptionalVariant['variant'], isSmall: boolean) {
  if (!v) {
    return isSmall ? 'small' : 'p';
  }
  return v;
}

const FlatButton: FC<FlatButtonProps & { variant: FlatButtonVariant }> = forwardRef(
  (
    {
      isPro = false,
      variant,
      isSmall = false,
      children,
      className,
      isDark = false,
      textProps = {},
      withAnimation,
      ...rest
    },
    buttonRef
  ) => {
    const { flavour: textFlavour, withArrow = false, variant: textVariant, ...restTextProps } = textProps;
    const rootClassName = classnames(
      'flat-button',
      variant,
      {
        dark: isDark,
        pro: isPro,
        small: isSmall,
      },
      textFlavour,
      className
    );

    return (
      <AnimatedButton
        ref={buttonRef}
        className={rootClassName}
        withAnimation={withAnimation || variant === FlatButtonVariant.underlined ? false : true}
        {...rest}
      >
        <TextWithArrow
          flavour={buildTextFlavour(textFlavour, variant, isPro)}
          tag="span"
          variant={buildTextVariant(textVariant, isSmall)}
          withArrow={withArrow}
          {...restTextProps}
        >
          {children}
        </TextWithArrow>
      </AnimatedButton>
    );
  }
);

const PrimaryButton: FC<FlatButtonProps> = forwardRef((props, buttonRef) => (
  <FlatButton ref={buttonRef} variant={FlatButtonVariant.primary} {...props} />
));
const SecondaryButton: FC<FlatButtonProps> = forwardRef((props, buttonRef) => (
  <FlatButton ref={buttonRef} variant={FlatButtonVariant.secondary} {...props} />
));
const UnderlinedButton: FC<FlatButtonProps> = forwardRef((props, buttonRef) => (
  <FlatButton ref={buttonRef} variant={FlatButtonVariant.underlined} {...props} />
));
export { PrimaryButton, SecondaryButton, UnderlinedButton, FlatButton };
