/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useTagPlan } from 'features/tagPlan/business/useTagPlan';
import { useRouter } from 'next/router';
import React, { ComponentPropsWithRef, FC, useEffect } from 'react';
import { AdvertService } from '@adverts/business/Advert.service';
import { ADVERT_FORMAT_ID } from '@adverts/business/Adverts.contants';
import classnames from 'classnames';
import { ATLevel2 } from 'features/tagPlan/business/TagPlan.types';

import './GenericAd.scss';

export type AdProps = Omit<ComponentPropsWithRef<'div'>, 'id'> & {
  formatId: ADVERT_FORMAT_ID;
};

const GenericAd: FC<AdProps> = ({ formatId, className, ...rest }) => {
  const rootClass = classnames(
    'generic-ad',
    {
      'ad-block flex justify-center': formatId === ADVERT_FORMAT_ID.Block,
      'ad-native': formatId === ADVERT_FORMAT_ID.NativeAd || formatId === ADVERT_FORMAT_ID.NativeAdBis,
      'ad-native-small': formatId === ADVERT_FORMAT_ID.SmallNative,
      'ad-video': formatId === ADVERT_FORMAT_ID.InlineVideo,
    },
    className
  );

  const { tagClick } = useTagPlan();

  function handleTagClick() {
    tagClick({
      level2: ATLevel2.Ads,
      tagPageNameI18nArray: [
        { code: 'Native-ads', notI18n: true },
        { code: String(formatId), notI18n: true },
      ],
    });
  }

  const router = useRouter();

  useEffect(() => AdvertService.render(formatId), [formatId, router]);

  return process.env.NEXT_STATIC_ADS_STATUS === 'disabled' ? null : (
    <div className={rootClass} id={`sas_${formatId}`} onClick={handleTagClick} {...rest} />
  );
};

export { GenericAd };
